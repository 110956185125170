var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-services animate__animated animate__fadeIn pt-4"},[_c('img',{staticClass:"w-100 mb-4",staticStyle:{"display":"none"},attrs:{"src":require('@/assets/banners/address-updates.png')},on:{"load":(e) => {
          e.target.style.display = 'block';
          _vm.hideSpinner('spinner-banner');
        }}}),_vm._m(0),_c('h5',{staticClass:"fs-la"},[_vm._v(" Let's narrow it down, which types of organisations do you need to update? "),_c('i',{staticClass:"fa-solid fa-circle-info",on:{"click":function($event){return _vm.openInfoTooltip()}}})]),_c('p',{staticClass:"fs-md"},[_vm._v("We'll get your specific selections on the next page")]),_c('div',{staticClass:"row mb-4"},_vm._l((_vm.services),function(item,index){return _c('div',{key:index,staticClass:"col-3",staticStyle:{"padding":"4px 2px"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center m-1 p-1 squares h-100 hvr",class:{ 'hvr-radial-out': !_vm.isMobile(), selected: item.value },on:{"click":function($event){return _vm.selectService(item.inform, item.value)}}},[(item.name==='Banks')?_c('div',{staticClass:"beta"},[_c('p',{staticClass:"fw-bold mb-0 fs-beta",staticStyle:{"font-size":"11px"}},[_vm._v("Beta")])]):_vm._e(),_c('div',[_c('img',{staticStyle:{"display":"none"},attrs:{"src":`${_vm.urlBucket}/form/assets/${item.img}`},on:{"load":(e) => {
                  e.target.style.display = 'unset';
                  _vm.hideSpinner(`spinner-${index}`);
                }}}),_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","id":`spinner-${index}`,"alt":""}}),_c('p',{staticClass:"fw-bold mb-0 fs-sm"},[_vm._v(_vm._s(item.name))])])])])}),0),_c('div',{staticClass:"checkboxes mb-3"},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('p-check',{staticClass:"pretty p-icon p-curve p-thick p-jelly conditions",staticStyle:{"font-size":"22px"},attrs:{"name":"check","color":"primary"},on:{"change":_vm.toggleTerms},model:{value:(_vm.checkValue),callback:function ($$v) {_vm.checkValue=$$v},expression:"checkValue"}},[_c('div',{staticClass:"state"},[_c('i',{staticClass:"icon mdi mdi-check",staticStyle:{"margin-left":"-67%"}})])]),_c('p',{staticClass:"mb-0 fs-md c-pointer",on:{"click":function($event){return _vm.autoClickCheckbox('conditions')}}},[_vm._v(" To support with my selected services, I agree to SlothMove's "),_c('a',{attrs:{"href":"https://slothmove.com/terms-and-conditions/","target":"_blank"}},[_vm._v("terms")])])],1),(_vm.errors.termsChecked)?_c('p',{staticClass:"fs-md",staticStyle:{"color":"#c64a45"}},[_vm._v(" * You must agree to our terms and conditions ")]):_vm._e()]),(_vm.informHMRC)?_c('div',{staticClass:"label-pages hmrc d-flex p-2 align-items-center"},[_c('img',{staticClass:"mx-2",attrs:{"src":`${_vm.urlBucket}/form/assets/hmrc.png`,"width":"40px","alt":""}}),_c('p',{staticClass:"ms-1 fs-sm mb-0"},[_vm._v(" We will notify HMRC within 3 days and due to demand, this may take HMRC up to 4 weeks to reflect in your account ")])]):_vm._e(),(_vm.informDWP)?_c('div',{staticClass:"label-pages hmrc d-flex p-2 align-items-center mt-3"},[_c('img',{staticClass:"mx-2",attrs:{"src":`${_vm.urlBucket}/form/assets/hmrc.png`,"width":"40px"}}),_c('p',{staticClass:"ms-1 fs-sm mb-0"},[_vm._v(" We will notify DWP within 3 days and due to demand, this may take DWP up to 12 weeks to reflect in your account ")])]):_vm._e(),(
        _vm.showLabelServices() &&
        (_vm.informMobile ||
          _vm.informLoyalty ||
          _vm.informBanks ||
          _vm.informPension ||
          _vm.informGyms ||
          _vm.informCharities)
      )?_c('div',{staticClass:"label-pages d-flex p-2 align-items-center mt-3"},[_c('img',{staticClass:"ms-2 me-2",attrs:{"src":require('@/assets/check-y.png'),"width":"30px","alt":""}}),_c('p',{staticClass:"ms-1 fs-sm mb-0"},[_vm._v("Excellent, you can select your specific companies shortly")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center my-5",attrs:{"id":"spinner-banner"}},[_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"100px","alt":""}})])
}]

export { render, staticRenderFns }